div.navbar-wrap {
    line-height: 1.15;
    -webkit-font-smoothing: antialiased;
    -webkit-box-direction: normal;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    position: fixed;
    bottom: 24px;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 1001;
    transition: all .32s ease-in-out, -webkit-transform .32s ease-in-out;
    transform: translate3d(0px, calc(100% + 48px), 0px);
    opacity: 1;
}

nav.navbar {
    line-height: 1.15;
    -webkit-font-smoothing: antialiased;
    -webkit-box-direction: normal;
    padding: 0;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 8px 24px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .12);
    border-radius: 20px;
    max-width: 398px;
    width: 100%;
    margin: 0 8px;
    display: flex;
}

.navbar_button:first-child {
    margin-left: 4px;
    padding-left: 7px;
}

.navbar_button:last-child {
    margin-right: 4px;
    padding-right: 7px;
}


.navbar__button {
    line-height: 1.15;
    -webkit-font-smoothing: antialiased;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow: visible;
    user-select: none;
    text-decoration: none;
    background: transparent;
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
    border: none;
    outline: none;
    flex-wrap: nowrap;
    align-content: center;
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: #202333;
    transition: transform .08s ease-in-out, -webkit-transform .08s ease-in-out;
    height: 66px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 18%;
}

.navbar__button:hover {
    cursor: pointer;
    transform: scale(0.95);
}

.navbar__button span.navbar__button-text {
    -webkit-font-smoothing: antialiased;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-direction: normal;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    margin-top: 4px;
    font-size: 10px;
    line-height: 14px;
    color: rgba(32, 35, 51, .7);
}

div.button__icon-wrap {
    -webkit-font-smoothing: antialiased;
    font-size: 100%;
    line-height: 1.15;
    text-transform: none;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-direction: normal;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    position: relative;
    padding: 4px 10px;
    z-index: 1;
    color: #fff;
}

div.button__icon-wrap:before {
    background: #202333;
    -webkit-box-shadow: 0 8px 24px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .12);
    box-shadow: 0 8px 24px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .12);
    z-index: -1;
    content: "";
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0;
    right: 0;
    border-radius: 8px;
}

.location-button--wrapper {
    position: absolute;
    top: -50px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
}

.location-button--wrapper:hover {
    cursor: pointer;
}

.location-button--icon {
    margin: auto;
    height: 1.2em;
    width: 1.2em;
    padding-right: 0.1em;
}

/*=====Order details menu styles=====*/

/* Custom button */
.btn {
    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    background-color: rgb(176, 176, 176);
    border: 0;
    border-radius: 10px;
    font-size: 15px;
}

.btn-text {
    font-family: YS Text, Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: inherit;
}

.btn-icon {
    height: 1.5em;
    margin-left: 0.5em;
    /* padding-bottom: 0.4em; */
}

.btn:active {
    transform: scale(0.98);
    /* Scaling button to 0.98 to its original size */
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    /* Lowering the shadow */
}

/* Order details menu styling */

.order-details-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em;
}

/* Preferences menu styling */

.preferences-menu-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 3vw;
    margin-bottom: 1em;
}

.confirm-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
  }


/*=====Active order menu styles=====*/
.active-order-menu__container {
    text-align: center;
    font-size: 16px;
    font-family: "Roboto", "Lucida Grande", "DejaVu Sans", "Bitstream Vera Sans", Verdana, Arial, sans-serif;
}

.active-order-menu__heading {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.active-order-menu__flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.8em;
}

.active-order-menu__driver-info {
    display: block;
    margin-left: 1.5em;
}

.active-order-menu__driver-photo {
    height: 6.25em;
    border-radius: 50%;
    margin-bottom: 0.5em;
}

.active-order-menu__driver-name {
    margin-bottom: 0.6em;
    display: block;
}

.active-order-menu__car-info {
    margin-bottom: 1em;
    display: block;
}

.active-order-menu__number-plate {
    font-size: 1.5em;
    font-weight: bold;
    background-color: #D9D9D9;

    border-radius: 5px;
    width: 6em;
    margin: 0 auto 1em;
}

/*.active-order-menu__order-status {*/
/*    font-weight: bold;*/
/*    color: white;*/
/*    background-color: #17ff32;*/
/*    padding: 10px 15px;*/
/*    border-radius: 5px;*/
/*}*/

.active-order-menu__order-status {
    font-size: 0.9em;
    font-weight: bold;
    color: white;
    background-color: #F4C70E;
    /*background-color: #17ff32;*/
    border-radius: 10px;
    padding: 10px 0;
    width: 90%;
    margin: 0 auto;
}

.center-text {
    /*margin: 50vh auto;*/
    text-align: center;
    animation: shim 3s infinite;
}

@keyframes shim {
    0%   {opacity: 100%}
    50%  {opacity: 10%}
    100% {opacity: 100%}
}
